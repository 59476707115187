import { useTranslation } from "react-i18next";

import { Arrange, Button, Link } from "@aviary";
import type { CurrentMFAProgress } from "@shared/components/MultifactorAuthentication/MFASteps";
import { l } from "@unauthenticated/shared/locales/i18n";

interface Props {
  currentProgress: CurrentMFAProgress;
}

const NavigationButtons = ({ currentProgress }: Props) => {
  const { currentStep, handleStepChange, isNextDisabled, isLoading, mfaToken, redirectPath } =
    currentProgress;
  const { t } = useTranslation();

  const nextButtonText = currentStep.nextLabelOverride || t(l.common.Next);

  let PreviousStepButton = (
    <Button intention="textSystem" onClick={() => handleStepChange(true)} isLoading={isLoading}>
      {t(l.common.Back)}
    </Button>
  );
  let NextStepButton = (
    <Button onClick={() => handleStepChange()} isLoading={isLoading} disabled={isNextDisabled}>
      {nextButtonText}
    </Button>
  );

  if (currentStep.nextStep === null) {
    NextStepButton = null;
  }

  // if mfaToken is null, they're allowed to skip MFA setup
  if (currentStep.previousStep === null) {
    if (mfaToken === null) {
      PreviousStepButton = (
        <Link
          onClick={() => {
            window.location.assign(redirectPath);
          }}
          isUnderLined={false}
        >
          {t(l.auth.MFA.skipForNow)}
        </Link>
      );
    } else {
      PreviousStepButton = null;
    }
  }

  if (currentStep.step === "displayBackupCode") {
    PreviousStepButton = null;
    NextStepButton = (
      <Button
        onClick={() => {
          window.location.assign(redirectPath);
        }}
        isLoading={isLoading}
      >
        {nextButtonText}
      </Button>
    );
  }

  return (
    <Arrange alignment="center">
      {PreviousStepButton}
      {NextStepButton}
    </Arrange>
  );
};

export { NavigationButtons };
