import { useTranslation } from "react-i18next";

import { Column, Columns, Link, Separator, Typography } from "@aviary";
import PhoneScreenSVGComponent from "@shared/assets/images/PhoneScreen";
import { getPlatformName } from "@shared/helpers/getPlatformName";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { l } from "@shared/locales/i18n";

import * as styles from "./DownloadAuthenticator.styles";

const DownloadAuthenticator = () => {
  const { t } = useTranslation();
  const { storePlatform } = useSharedGlobalConfig();

  return (
    <>
      <div css={styles.header}>
        <Typography type="h1">{t(l.auth.MFA.downloadAuthenticator.title)}</Typography>
      </div>
      <Columns preserveMobileColumns>
        <Column columnWidth={2}>
          <PhoneScreenSVGComponent css={styles.svgImage} />
        </Column>
        <Column columnWidth={10} preserveMobileColumns>
          <Typography isSecondaryWeight isMarginless>
            {t(l.auth.MFA.step1)}
          </Typography>
          <Typography isMarginless>
            {t(l.auth.MFA.downloadAuthenticator.downloadAnAuthenticatorApp)}
          </Typography>
          <ul css={styles.authenticatorList}>
            <li>
              <Link href="google.ca">
                {t(l.auth.MFA.downloadAuthenticator.googleAuthenticator)}
              </Link>
            </li>
            <li>
              <Link href="google.ca">
                {t(l.auth.MFA.downloadAuthenticator.microsoftAuthenticator)}
              </Link>
            </li>
            <li>
              <Link href="google.ca">
                {t(l.auth.MFA.downloadAuthenticator.lastPassAuthenticator)}
              </Link>
            </li>
            <li>
              <Link href="google.ca">
                {t(l.auth.MFA.downloadAuthenticator.twilioAuthyAuthenticator)}
              </Link>
            </li>
          </ul>
        </Column>
      </Columns>
      <Separator css={styles.separator} />
      <Columns preserveMobileColumns>
        <Column columnWidth={2}>
          <PhoneScreenSVGComponent css={styles.svgImage} />
        </Column>
        <Column columnWidth={10} preserveMobileColumns>
          <Typography isSecondaryWeight isMarginless>
            {t(l.auth.MFA.step2)}
          </Typography>
          <Typography>
            {t(l.auth.MFA.downloadAuthenticator.openTheApp, {
              platform: t(getPlatformName(storePlatform)),
            })}
          </Typography>
        </Column>
      </Columns>
    </>
  );
};

export { DownloadAuthenticator };
