import type { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { Arrange, Pill, SelectableTile, Typography } from "@aviary";
import { l } from "@shared/locales/i18n";
import { TwoFactorAuthenticationMethods as AuthMethod } from "@shared/types/graphqlGenerated";

interface Props {
  selectedAuthMethod: AuthMethod;
  onSelect: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ChooseAuthenticationMethod = ({ selectedAuthMethod, onSelect }: Props) => {
  const { t } = useTranslation();
  const renderAuthMethod = (method: AuthMethod) => {
    switch (method) {
      case AuthMethod.Sms:
        return (
          <SelectableTile
            aria-label={t(l.auth.MFA.chooseAuthenticationMethod.useTextMessage)}
            name={AuthMethod.Sms}
            onChange={onSelect}
            checked={selectedAuthMethod === AuthMethod.Sms}
            value={AuthMethod.Sms}
            isRadio
            isInline
            isContentHeight
          >
            <Typography type="h5" isMarginless>
              {t(l.auth.MFA.chooseAuthenticationMethod.useTextMessage)}
            </Typography>
            <Typography>
              {t(l.auth.MFA.chooseAuthenticationMethod.receiveOneTimeCodeViaTextMessage)}
            </Typography>
          </SelectableTile>
        );
      case AuthMethod.App:
        return (
          <SelectableTile
            aria-label={t(l.auth.MFA.chooseAuthenticationMethod.useAuthenticatorApp)}
            name={AuthMethod.App}
            onChange={onSelect}
            checked={selectedAuthMethod === AuthMethod.App}
            value={AuthMethod.App}
            isRadio
            isInline
            isContentHeight
          >
            <Arrange alignment="center" spacing="xxsmall">
              <Typography type="h5">
                {t(l.auth.MFA.chooseAuthenticationMethod.useAuthenticatorApp)}
              </Typography>
              <Pill size="small" isColor="system">
                {t(l.auth.MFA.chooseAuthenticationMethod.moreSecure)}
              </Pill>
            </Arrange>
            <Typography>
              {t(l.auth.MFA.chooseAuthenticationMethod.generateCodeViaAuthenticatorApp)}
            </Typography>
          </SelectableTile>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderAuthMethod(AuthMethod.Sms)}
      {renderAuthMethod(AuthMethod.App)}
    </>
  );
};

export { ChooseAuthenticationMethod };
