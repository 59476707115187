import { css } from "@emotion/react";
import { borders } from "aviary-tokens";

export const imageWidth = {
  small: css`
    width: 415px;
  `,
  medium: css`
    width: 632px;
  `,
};

export const borderRadius = css`
  border-radius: ${borders.radiusBase};
`;
