import { gql } from "@apollo/client";

const EnableMFA_Shared_Mutation = gql`
  mutation EnableMFA_Shared_Mutation($input: AuthUserTwoFactorAuthenticationEnableInput!) {
    auth {
      userEnableTwoFactorAuthentication(input: $input) {
        clientMutationId
        twoFactorAuthentication {
          id
          isEnabled
        }
        newBackupCode
        redirectPath
        errors {
          message
        }
      }
    }
  }
`;

export { EnableMFA_Shared_Mutation };
