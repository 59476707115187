import type { ChangeEvent, FormEvent } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Separator, Spacer, Typography } from "@aviary";
import { isEmailValid } from "@shared/components/EmailInput/EmailValidation/EmailValidation";
import { AppleSSOButton } from "@shared/components/LoginOptions/AppleSSOButton/AppleSSOButton";
import { GoogleSSOButton } from "@shared/components/LoginOptions/GoogleSSOButton/GoogleSSOButton";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { Size } from "@shared/types/sizes";
import { FormWrapper } from "@unauthenticated/shared/components/FormWrapper/FormWrapper";
import { PasswordInput } from "@unauthenticated/shared/components/PasswordInput/PasswordInput";
import { isPasswordValid } from "@unauthenticated/shared/components/PasswordInput/PasswordValidation/PasswordValidation";
import { PractitionerSignUpEmailInput } from "@unauthenticated/shared/components/PractitionerSignUpEmailInput/PracitionerSignUpEmailInput";
import { RecaptchaErrorBox } from "@unauthenticated/shared/components/RecaptchaErrorBox/RecaptchaErrorBox";
import { useGlobalConfig } from "@unauthenticated/shared/context/GlobalConfig/GlobalConfig";
import { l } from "@unauthenticated/shared/locales/i18n";

import type { SimplifySignUpWithSignUpPageProps } from "../types";

import * as styles from "./CreateAccountPageTwo.styles";

const CreateAccountPageTwo = ({
  formData,
  errors,
  recaptchaError,
  setFormData,
  logField,
  onSignInRedirect,
  onSubmit,
  additionalAttributes,
  pageVersion,
  signUpPage,
  isLoading,
  isAccountExists,
  setIsAccountExists,
}: SimplifySignUpWithSignUpPageProps) => {
  const { t } = useTranslation();

  const { emailRegex } = useGlobalConfig();
  const { isEmerson } = useSharedGlobalConfig();

  const [emailDirty, setEmailDirty] = useState(false);
  const [passwordDirty, setPasswordDirty] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));

    // This component will fire the onChange event to display any existing
    // formData value in which case we don't want to delete the error
    if (formData[name] !== value && errors?.[name]) {
      delete errors[name];
    }
  };

  const setEmail = (email: string) => {
    setFormData(prevState => ({ ...prevState, email }));
  };

  const onEmailBlur = e => {
    if (isEmailValid(formData.email, emailRegex)) {
      logField("email");
    }
    setEmailDirty(true);
    if (e?.target.value) {
      handleChange(e);
    }
  };

  const onPasswordBlur = () => {
    if (isPasswordValid(formData.password)) {
      logField("password");
    }
  };

  const isFormValid = () => {
    const emailValid = isEmailValid(formData.email, emailRegex);
    const passwordValid = isPasswordValid(formData.password);

    return passwordValid && emailValid;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEmailDirty(true);
    setPasswordDirty(true);
    if (!isFormValid) {
      return;
    }

    onSubmit();
  };

  const renderOAuth = () =>
    !isEmerson && (
      <div css={styles.contentDiv}>
        <Spacer height="double" />
        <Separator text="OR" />
        <Spacer height="one" />
        <GoogleSSOButton
          pracTypeId={formData?.practitionerType?.id}
          pageVersion={pageVersion}
          additionalAttributes={additionalAttributes}
          signUpPage={signUpPage}
          buttonStyles={styles.SSOButtons}
          formData={{
            firstName: formData?.firstName,
            lastName: formData?.lastName,
            storeName: formData?.dispensaryName,
            tosAcceptedAt: formData?.tosTimestamp,
          }}
        />
        <AppleSSOButton
          pracTypeId={formData?.practitionerType?.id}
          pageVersion={pageVersion}
          additionalAttributes={additionalAttributes}
          buttonStyles={styles.SSOButtons}
          signUpPage={signUpPage}
          formData={{
            firstName: formData?.firstName,
            lastName: formData?.lastName,
            storeName: formData?.dispensaryName,
            tosAcceptedAt: formData?.tosTimestamp,
          }}
        />
      </div>
    );

  return (
    <form
      role="form"
      onSubmit={handleSubmit}
      css={[styles.marginTop, styles.contentDiv]}
      noValidate
      data-e2e="practitioner-sign-up-form"
      data-gtm-id="prac-sign-up-form"
    >
      <Typography type="h3">{t(l.practitionerSignUp.PracOnboarding.CreateYourAccount)}</Typography>
      <Spacer height="one" />
      <FormWrapper style={{ maxWidth: "100%" }}>
        <PractitionerSignUpEmailInput
          value={formData.email}
          onChange={handleChange}
          setEmail={setEmail}
          isDirty={emailDirty}
          required
          isAccountExists={isAccountExists}
          setIsAccountExists={setIsAccountExists}
          onSignInRedirect={onSignInRedirect}
          onBlur={onEmailBlur}
          wrapperStyles={styles.input}
        />
        <PasswordInput
          value={formData.password}
          onChange={handleChange}
          isLoading={false}
          required
          isDirty={passwordDirty}
          onFocus={() => setPasswordDirty(true)}
          onBlur={onPasswordBlur}
          wrapperStyles={styles.input}
          errors={errors?.password}
        />
        <Button
          type="submit"
          isFullWidth
          data-e2e="create-practitioner-button"
          isLoading={isLoading}
          size={Size.LARGE}
        >
          {t(l.practitionerSignUp.PracOnboarding.CreateAccount)}
        </Button>
      </FormWrapper>
      {renderOAuth()}
      <Spacer height="one" />
      {recaptchaError && <RecaptchaErrorBox />}
    </form>
  );
};

export { CreateAccountPageTwo };
