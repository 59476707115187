import { css } from "@emotion/react";

export const bannerWrapper = css`
  display: flex;
  justify-content: center;
  margin-top: 110px;
`;

export const banner = css`
  flex: 1;
`;
