import { PromoBanner } from "@shared/components/PromoBanner/PromoBanner";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useFlippers } from "@shared/hooks/useFlippers/useFlippers";

import * as styles from "./PromotionBanner.styles";

interface PromotionBannerProps {
  isActivePromotion: boolean;
}

const PromotionBanner = ({ isActivePromotion = false }: PromotionBannerProps) => {
  const [isBFCMBannersEnabled] = useFlippers("phoenix_bfcm_2024_updates");
  const { desktop } = useBreakpoints();

  if (!isBFCMBannersEnabled || !isActivePromotion) return null;

  const getSize = () => {
    if (desktop.lessThan) return "small";

    return "medium";
  };

  return (
    <aside css={styles.bannerWrapper}>
      <PromoBanner size={getSize()} css={styles.banner} />
    </aside>
  );
};

export { PromotionBanner, type PromotionBannerProps };
