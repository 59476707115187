import type * as Types from '@shared/types/graphqlGenerated.ts';

import * as Operations from './ResendMFAPasscode.mutation';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResendMfaPasscodeSharedMutationVariables = Types.Exact<{
  input: Types.AuthUserTwoFactorAuthenticationResendEmailSetupInput;
}>;


export type ResendMfaPasscodeSharedMutation = { auth?: Types.Maybe<{ userResendEmailSetupTwoFactorAuthentication?: Types.Maybe<(
      Pick<Types.AuthUserTwoFactorAuthenticationResendEmailSetupPayload, 'sent'>
      & { errors: Pick<Types.AuthUserTwoFactorAuthenticationResendEmailSetupError, 'message'> }
    )> }> };


export type ResendMfaPasscodeSharedMutationMutationFn = Apollo.MutationFunction<ResendMfaPasscodeSharedMutation, ResendMfaPasscodeSharedMutationVariables>;

/**
 * __useResendMfaPasscodeSharedMutation__
 *
 * To run a mutation, you first call `useResendMfaPasscodeSharedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendMfaPasscodeSharedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendMfaPasscodeSharedMutation, { data, loading, error }] = useResendMfaPasscodeSharedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendMfaPasscodeSharedMutation(baseOptions?: Apollo.MutationHookOptions<ResendMfaPasscodeSharedMutation, ResendMfaPasscodeSharedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendMfaPasscodeSharedMutation, ResendMfaPasscodeSharedMutationVariables>(Operations.ResendMFAPasscode_Shared_Mutation, options);
      }
export type ResendMfaPasscodeSharedMutationHookResult = ReturnType<typeof useResendMfaPasscodeSharedMutation>;
export type ResendMfaPasscodeSharedMutationMutationOptions = Apollo.BaseMutationOptions<ResendMfaPasscodeSharedMutation, ResendMfaPasscodeSharedMutationVariables>;