import { css, type Theme } from "@emotion/react";

import { dimensions } from "@styles";

export const fullHeight = css`
  height: 100%;
`;

export const columnsContainer = css`
  ${fullHeight}
  margin: 0;
`;

export const leftColumn = (theme: Theme) => css`
  background: ${theme.primary.backgroundBox};
  border-right: 1px solid ${theme.system.borderBase};
  padding: 16px 112px;
  text-align: center;

  @media (min-width: ${dimensions.tablet}) and (max-width: ${dimensions.tabletMax}) {
    padding: 16px;
  }

  @media (min-width: ${dimensions.desktop}) and (max-width: ${dimensions.desktopMax}) {
    padding-inline: 80px;
  }
`;

export const rightColumn = css`
  padding: 1rem;
  margin: auto;
`;

export const layoutContainer = css`
  flex: 1 0 auto;
`;
