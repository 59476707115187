import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Spacer, Typography } from "@aviary";
import { l } from "@shared/locales/i18n";

import * as styles from "./SecureYourAccount.styles";

interface Props {
  children: ReactNode;
}

const SecureYourAccount = ({ children }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div css={styles.header}>
        <Typography css={styles.title} type="h1" isMarginless>
          {t(l.auth.MFA.chooseAuthenticationMethod.title)}
        </Typography>
        <Typography isMarginless>{t(l.auth.MFA.chooseAuthenticationMethod.subtitle)}</Typography>
        <Spacer height="oneHalf" />
        <Typography isMarginless>
          {t(l.auth.MFA.chooseAuthenticationMethod.thisIsMandatory)}
        </Typography>
      </div>
      <Spacer height="double" />
      {children}
    </>
  );
};

export { SecureYourAccount };
