import { gql } from "@apollo/client";

const ResendMFAPasscode_Shared_Mutation = gql`
  mutation ResendMFAPasscode_Shared_Mutation(
    $input: AuthUserTwoFactorAuthenticationResendEmailSetupInput!
  ) {
    auth {
      userResendEmailSetupTwoFactorAuthentication(input: $input) {
        sent
        errors {
          message
        }
      }
    }
  }
`;

export { ResendMFAPasscode_Shared_Mutation };
