import { Trans, useTranslation } from "react-i18next";

import { Link, Spacer, Typography, useToast } from "@aviary";
import { l } from "@shared/locales/i18n";
import { useLocation } from "@shared/react-router-dom/react-router-dom";

import type { ResendMfaPasscodeSharedMutation as ResendMFAPasscodeData } from "./ResendMFAPasscode.mutation.generated";
import { useResendMfaPasscodeSharedMutation as useResendMFAPasscode } from "./ResendMFAPasscode.mutation.generated";

import * as styles from "./ResendOtp.styles";

interface Props {
  phoneLast4?: string;
}

interface HistoryState {
  mfaToken?: string;
}

const ResendOtp = ({ phoneLast4 }: Props) => {
  const { state }: { state: HistoryState } = useLocation();

  const { t } = useTranslation();
  const { makeToast } = useToast();

  const onResendPasscodeComplete = (data: ResendMFAPasscodeData) => {
    const hasErrors = data?.auth?.userResendEmailSetupTwoFactorAuthentication?.errors?.message;
    const isSent = data?.auth?.userResendEmailSetupTwoFactorAuthentication?.sent;

    if (hasErrors || !isSent) {
      return makeToast("error", t(l.auth.MFA.enableWithSMS.textMessageNotSent));
    }

    if (isSent) {
      makeToast("success", t(l.auth.MFA.enableWithSMS.textMessageSent, { phoneLast4 }));
    }
  };

  const [resendPasscode, { loading: loadingResendPasscode }] = useResendMFAPasscode({
    onCompleted: onResendPasscodeComplete,
    onError: ({ message }) => makeToast("error", message),
  });

  const handleResendPasscode = () => {
    if (loadingResendPasscode) return;

    resendPasscode({
      variables: {
        input: {
          token: state?.mfaToken,
        },
      },
    });
  };

  return (
    <div css={styles.wrapper}>
      <Typography type="footnote" isMarginless>
        <Trans i18nKey={l.auth.MFA.enableWithSMS.didntGetTextMessage}>
          Didn't get the text message?
          <Link onClick={handleResendPasscode}>Resend</Link>.
        </Trans>
      </Typography>
      <Spacer height="half" />
      <Typography type="footnote" css={styles.expirationMessage} isMarginless>
        {t(l.auth.MFA.enableWithSMS.codeWillExpireIn10)}
      </Typography>
    </div>
  );
};

export { ResendOtp };
