import ReactRailsUJS from "@shared/polyfill/react_ujs";
import { hello } from "@shared/utils/hello";
import { initSentry } from "@shared/utils/initSentry/initSentry";
import { isSentryTunnelEnabled } from "@shared/utils/initSentry/isSentryTunnelEnabled/isSentryTunnelEnabled";

const componentRequireContext = require.context("@unauthenticated/signupSignin", false);

initSentry({
  enableRouterInstrumentation: true,
  tracesSampleRate: 0.01,
  enableSentryTunnel: isSentryTunnelEnabled(),
});

// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext);
hello();
