import { css } from "@emotion/react";

import * as dimensions from "@styles/emotion-styles/dimensions";

export const contentDiv = css`
  display: flex;
  margin-bottom: 2rem;
  flex-direction: column;
  align-items: center;
  width: 22rem;
  @media (min-width: ${dimensions.tablet}) {
    width: 32rem;
  }
`;

export const marginTop = css`
  margin-top: 0rem;
  @media (min-width: ${dimensions.tablet}) {
    margin-top: 2rem;
  }
`;

export const input = css`
  margin-bottom: 1rem;
  :not(:last-of-type) {
    margin-top: 0.5rem;
  }
`;

export const SSOButtons = css`
  width: 22rem;
  margin-top: 1rem;
  padding-left: 3rem;
  align-items: center;
  text-align: center;
`;
