import type { QueryHookOptions } from "@apollo/client";
import { useQuery, gql } from "@apollo/client";

import type { User } from "@shared/types/graphqlGenerated";

const HasMultipleStorePlatforms_Shared_Query = gql`
  query HasMultipleStorePlatforms_Shared_Query {
    currentOffice {
      id
      user {
        id
        hasMultipleStorePlatforms
      }
    }
  }
`;

type UserSplinter = Pick<User, "id" | "hasMultipleStorePlatforms">;

interface PractitionerOfficeSplinter {
  user: UserSplinter;
}

interface HasMultipleStorePlatformsData {
  currentOffice: PractitionerOfficeSplinter;
}

const useHasMultipleStorePlatformsQuery = (
  options?: QueryHookOptions<HasMultipleStorePlatformsData>
) => useQuery<HasMultipleStorePlatformsData>(HasMultipleStorePlatforms_Shared_Query, options);

export type { HasMultipleStorePlatformsData };

/* eslint-disable @fullscript/gql-no-manual-hook-declaration */
export { useHasMultipleStorePlatformsQuery, HasMultipleStorePlatforms_Shared_Query };
