import { useTranslation } from "react-i18next";

import type { AviarySize } from "@aviary/types";
import { l } from "@shared/locales/i18n";

import smallBanner2x from "./assets/BFCM2024-Product-Patient-Web-Banner-415x150.png";
import smallBanner1x from "./assets/BFCM2024-Product-Patient-Web-Banner-415x150x1.png";
import mediumBanner2x from "./assets/BFCM2024-Product-Patient-Web-Banner-632x150.png";
import mediumBanner1x from "./assets/BFCM2024-Product-Patient-Web-Banner-632x150x1.png";

import * as styles from "./PromoBanner.styles";

type PromoBannerSizes = Extract<AviarySize, "small" | "medium">;

interface PromoBannerProps {
  size?: PromoBannerSizes;
}

const PromoBanner = ({ size = "medium", ...rest }: PromoBannerProps) => {
  const { t } = useTranslation();

  const imageStyles = [styles.borderRadius, styles.imageWidth[size]];

  const bannerSizes: Record<PromoBannerSizes, { image: { 1: string; 2: string }; testId: string }> =
    {
      small: { image: { 1: smallBanner1x, 2: smallBanner2x }, testId: "promo-banner-small" },
      medium: { image: { 1: mediumBanner1x, 2: mediumBanner2x }, testId: "promo-banner-medium" },
    };

  return (
    <img
      src={bannerSizes[size].image[1]}
      srcSet={`${bannerSizes[size].image[1]}, ${bannerSizes[size].image[2]} 2x`}
      alt={t(l.patientPromotions.bannerAltText)}
      data-testid={bannerSizes[size].testId}
      css={imageStyles}
      {...rest}
    />
  );
};

export { PromoBanner, type PromoBannerSizes };
