import { Spacer } from "@aviary";
import { useLocation } from "@shared/react-router-dom/react-router-dom";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import type { PatientAttributes } from "@unauthenticated/shared/data/types/PatientAttributes.d";
import { SignUpHeader } from "@unauthenticated/signupSignin/components/SignUpHeader/SignUpHeader";
import { SignUpTitle } from "@unauthenticated/signupSignin/components/SignUpTitle/SignUpTitle";
import { StoreLandingLayout } from "@unauthenticated/signupSignin/components/StoreLandingLayout/StoreLandingLayout";
import { StoreLogo } from "@unauthenticated/signupSignin/components/StoreLogo/StoreLogo";
import type { LocationType } from "@unauthenticated/signupSignin/data/types/PatientAuth";
import { PasswordForm } from "@unauthenticated/signupSignin/scenes/PatientAuthPasswordPage/PasswordForm/PasswordForm";

interface Props {
  patientAttributes: PatientAttributes;
  additionalAttributes?: any;
  ssoErrors?: string;
  storeId?: string;
  activePatientPromotion?: boolean;
}

const PatientAuthPasswordPage = ({
  patientAttributes,
  additionalAttributes,
  storeId,
  activePatientPromotion,
}: Props) => {
  const formVersion = "StoreSignInPage";
  const { storeLogo, storeName, treatmentPlanSlug, uuid, storeSlug, pracAvatar } =
    patientAttributes;
  const FORGOT_PASSWORD_LINK = authRoutes.welcomeForgotPassword(
    storeSlug,
    uuid || treatmentPlanSlug
  );
  const { state }: LocationType = useLocation();

  return (
    <StoreLandingLayout
      storeName={storeName}
      pracAvatar={pracAvatar}
      isActivePromotion={activePatientPromotion}
    >
      <SignUpHeader>
        <StoreLogo storeLogo={storeLogo} storeName={storeName} />
        <SignUpTitle title={storeName} />
      </SignUpHeader>
      <Spacer height="double" />
      <PasswordForm
        additionalAttributes={{ ...additionalAttributes, formVersion }}
        email={state?.email}
        forgotPasswordLink={FORGOT_PASSWORD_LINK}
        storeId={storeId}
      />
    </StoreLandingLayout>
  );
};

export { PatientAuthPasswordPage };
