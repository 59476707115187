import type { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Link, Spacer, Typography } from "@aviary";
import { sharedRoutes } from "@shared/data/sharedRoutes";
import { l } from "@shared/locales/i18n";

import * as styles from "./EnterPhoneNumber.styles";

interface Props {
  children: ReactNode;
}

const EnterPhoneNumber = ({ children }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div css={styles.header}>
        <Typography css={styles.title} type="h1">
          {t(l.auth.MFA.enterMobilePhone.title)}
        </Typography>
        <Typography>{t(l.auth.MFA.enterMobilePhone.subtitle)}</Typography>
        <Spacer height="half" />
        <Typography>{t(l.auth.MFA.enterMobilePhone.enterYourNumber)}</Typography>
      </div>
      <Spacer height="one" />
      {children}
      <Spacer height="oneHalf" />
      <Typography type="caption" data-testid="mobile-phone-disclaimer">
        <Trans i18nKey={l.auth.MFA.enterMobilePhone.disclaimer}>
          Using your mobile number for security means you accept our
          <Link href={sharedRoutes.external.privacyPolicy}>privacy policy</Link> and
          <Link href={sharedRoutes.external.termsOfService}>Terms of Service</Link>. Standard text
          message rates may apply.
        </Trans>
      </Typography>
    </>
  );
};

export { EnterPhoneNumber };
