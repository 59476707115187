import { css } from "@emotion/react";

export const authenticatorList = css`
  list-style-type: none;
  margin-top: 0.5rem;

  li {
    margin-bottom: 0.25rem;
  }
`;

export const separator = css`
  margin-bottom: 2rem;
`;

export const svgImage = css`
  align-self: center;
  width: 4rem;
  height: 4rem;
`;

export const header = css`
  text-align: center;
  margin-bottom: 28px;
`;
