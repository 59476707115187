import { faExclamationCircle, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import type { ChangeEvent, FormEvent } from "react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Avatar, Button, Spacer, Tooltip, Typography } from "@aviary";
import { FormFieldInformation } from "@aviary/components/FormFieldInformation";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useCallistoPracOnboardingExperiment } from "@shared/hooks/useCallistoPracOnboardingExperiment/useCallistoPracOnboardingExperiment";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { FontAwesomeIcon } from "@shared/react-fontawesome/react-fontawesome";
import type { PractitionerAdditionalAttributesType } from "@shared/types/PractitionerAdditionalAttributesType";
import { StorePlatform } from "@shared/types/StorePlatform";
import { DesignationSearch } from "@unauthenticated/shared/components/DesignationSearch/DesignationSearch";
import { DispensaryNameInput } from "@unauthenticated/shared/components/DispensaryNameInput/DispensaryNameInput";
import { FirstNameInput } from "@unauthenticated/shared/components/FirstNameInput/FirstNameInput";
import { FormWrapper } from "@unauthenticated/shared/components/FormWrapper/FormWrapper";
import { LastNameInput } from "@unauthenticated/shared/components/LastNameInput/LastNameInput";
import { IsNameValid } from "@unauthenticated/shared/components/NameValidation/NameValidation";
import { SSOErrorBox } from "@unauthenticated/shared/components/SSOErrorBox/SSOErrorBox";
import { TermsOfServiceCheckbox } from "@unauthenticated/shared/components/TermsOfServiceCheckbox/TermsOfServiceCheckbox";
import { l } from "@unauthenticated/shared/locales/i18n";

import * as styles from "../SimplifySignUp.styles";
import type { SimplifySignUpProps } from "../types";

interface Props extends SimplifySignUpProps {
  onClick: (e: FormEvent<HTMLFormElement>) => void;
  additionalAttributes?: PractitionerAdditionalAttributesType;
}

type FieldValidationStateType = {
  isFirstNameInvalid: boolean;
  isLastNameInvalid: boolean;
  isDispensaryNameInvalid: boolean;
  isPractitionerTypeInvalid: boolean;
  isTosCheckInvalid: boolean;
};

const CreateAccountPageOne = ({
  errors,
  onClick,
  formData,
  setFormData,
  logField,
  additionalAttributes,
  ssoErrors,
}: Props) => {
  const { isEmerson } = useSharedGlobalConfig();
  const { t } = useTranslation();
  const { phone, phoneLarge } = useBreakpoints();

  const [fieldValidationState, setFieldValidationState] = useState<FieldValidationStateType>({
    isFirstNameInvalid: false,
    isLastNameInvalid: false,
    isDispensaryNameInvalid: false,
    isPractitionerTypeInvalid: false,
    isTosCheckInvalid: false,
  });

  const isPeerReferral = !!additionalAttributes?.peerReferralDisplayAttributes;
  const isPracOnboardingEnabled = useCallistoPracOnboardingExperiment() && !isPeerReferral;
  const headerText = isEmerson
    ? t(l.practitionerSignUp.Emerson.CreateAccountHeader)
    : t(l.practitionerSignUp.startPractitionerAccount);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));

    if (errors?.[name]) {
      errors[name] = [];
    }
  };

  const getDefaultDispensaryName = () => {
    const { firstName, lastName } = formData;

    if (!firstName || !lastName) return "";

    const lastChar = lastName[lastName.length - 1];
    const s = lastChar === "s" || lastChar === "S" ? "" : "s";
    const fullName = `${firstName} ${lastName}'${s}`;

    if (isEmerson) {
      return t(l.common.DispensaryNameDefaultWithPlatform, {
        fullName,
        storePlatform: StorePlatform.EMERSON,
      });
    }

    return t(l.common.DispensaryDisplayName, { fullName });
  };

  const handleSetDesignationValue = practitionerType => {
    setFormData(prevState => ({ ...prevState, practitionerType }));
  };

  const onBlurPractitionerType = () => {
    setFieldValidationState(prevState => ({ ...prevState, isPractitionerTypeInvalid: false }));
  };

  const logFields = (inputName: string) => {
    const fieldsToLog = ["firstName", "lastName", "dispensaryName", "practitionerType"];
    if (fieldsToLog.includes(inputName)) {
      logField(inputName);
    }
  };

  const updateTimestamp = tosTimestamp => {
    setFormData(prevState => ({ ...prevState, tosTimestamp }));
  };

  const renderPractitionerTypeError = () => {
    if (fieldValidationState.isPractitionerTypeInvalid) {
      return <FormFieldInformation errors={[t(l.practitionerSignUp.healthProfessionValidation)]} />;
    }

    return null;
  };

  const renderTosError = () => {
    if (fieldValidationState.isTosCheckInvalid && !formData.tosTimestamp) {
      return (
        <div css={styles.tosError}>
          <FontAwesomeIcon icon={faExclamationCircle} css={styles.errorIcon} />
          <Typography customStyles={styles.errorText} type="footnote">
            {t(l.practitionerSignUp.agreeToSValidation)}
          </Typography>
        </div>
      );
    }

    return null;
  };

  const renderHeader = () => {
    if (isPeerReferral) {
      const { referrerFirstName, referrerAvatar } =
        additionalAttributes?.peerReferralDisplayAttributes;

      return (
        <div css={styles.peerReferralTitle}>
          {!!referrerAvatar && (
            <Avatar
              size="small"
              image={referrerAvatar}
              css={styles.userAvatar}
              label={`${referrerFirstName} avatar`}
            />
          )}
          <Typography type="h3">
            {t(l.practitionerSignUp.peerReferral.signUpTitle, { referrerFirstName })}
          </Typography>
        </div>
      );
    }

    return isPracOnboardingEnabled ? (
      <Typography type="h3">{t(l.practitionerSignUp.PracOnboarding.headerText)}</Typography>
    ) : (
      <>
        <Spacer height="double" />
        <Typography type="h2">{headerText}</Typography>
      </>
    );
  };

  const renderNextButton = () => {
    return (
      <Button
        data-e2e="next_button"
        type="submit"
        isFullWidth
        size={isPracOnboardingEnabled ? "large" : "medium"}
      >
        {isPracOnboardingEnabled
          ? t(l.practitionerSignUp.PracOnboarding.nextButtonText)
          : t(l.practitionerSignUp.NextSelectSignUpMethod)}
      </Button>
    );
  };

  const renderFirstLastNameFields = () => {
    const firstNameInput = (
      <FirstNameInput
        value={formData.firstName}
        onChange={handleChange}
        required
        isDirty={fieldValidationState.isFirstNameInvalid}
        isLoading={false}
        errors={errors?.firstName}
      />
    );

    const lastNameInput = (
      <LastNameInput
        value={formData.lastName}
        onChange={handleChange}
        required
        isDirty={fieldValidationState.isLastNameInvalid}
        isLoading={false}
        errors={errors?.lastName}
      />
    );

    const desktopLayout = (
      <div css={styles.row}>
        {firstNameInput}
        <Spacer width="oneHalf" />
        {lastNameInput}
      </div>
    );

    const mobileLayout = (
      <>
        <div css={styles.row}>{firstNameInput}</div>
        <div css={styles.row}>{lastNameInput}</div>
      </>
    );

    return phone.greaterThan ? desktopLayout : mobileLayout;
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let updatedFormData = { ...formData };
    if (formData.dispensaryName === "") {
      updatedFormData = {
        ...updatedFormData,
        dispensaryName: getDefaultDispensaryName(),
      };
      setFormData(updatedFormData);
    }

    const newFieldValidationState: FieldValidationStateType = {
      isFirstNameInvalid: false,
      isLastNameInvalid: false,
      isDispensaryNameInvalid: false,
      isPractitionerTypeInvalid: false,
      isTosCheckInvalid: false,
    };

    const invalidStates: FieldValidationStateType = {
      isFirstNameInvalid: !IsNameValid(updatedFormData.firstName),
      isLastNameInvalid: !IsNameValid(updatedFormData.lastName),
      isDispensaryNameInvalid: updatedFormData.dispensaryName.length < 4,
      isPractitionerTypeInvalid: !updatedFormData.practitionerType,
      isTosCheckInvalid: !updatedFormData.tosTimestamp,
    };

    Object.entries(invalidStates).forEach(([field, invalid]) => {
      if (invalid) {
        newFieldValidationState[field] = true;
      } else {
        logFields(field);
      }
    });
    setFieldValidationState(newFieldValidationState);

    const hasInvalidFields = Object.values(newFieldValidationState).some(Boolean);
    if (!hasInvalidFields) {
      onClick(e);
    }
  };

  const renderSubtitle = () => {
    if (isPracOnboardingEnabled) {
      return (
        <Trans i18nKey={l.practitionerSignUp.PracOnboarding.TellUsAboutYourself}>
          Tell us a bit about yourself to get started. All accounts are{" "}
          <strong css={styles.freeText}>100% free</strong>
        </Trans>
      );
    }

    return isEmerson ? (
      t(l.practitionerSignUp.Emerson.TellUsAboutYourPractice)
    ) : (
      <Trans i18nKey={l.practitionerSignUp.allAccountsAreFree} components={{ 1: <strong /> }} />
    );
  };

  const renderDispensaryNameTooltip = () => {
    if (!isEmerson) {
      if (isPracOnboardingEnabled && phoneLarge.lessThan) return null;

      const dispensaryNameTooltipContent = t(l.practitionerSignUp.dispensaryName, {
        patientTermPlural: "patients",
      });

      return (
        <Tooltip
          tooltipContent={dispensaryNameTooltipContent}
          css={styles.tooltip}
          data-testid="dispensary-name-tooltip"
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>
      );
    }
  };

  const renderDesignationSearchTooltip = () => {
    if (isPracOnboardingEnabled && phoneLarge.lessThan) return null;

    const designationTooltipContent = t(l.practitionerSignUp.matchHealthCareCredentials);

    return (
      <Tooltip
        tooltipContent={designationTooltipContent}
        css={styles.tooltip}
        data-testid="designation-search-tooltip"
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </Tooltip>
    );
  };

  return (
    <div css={styles.contentBox}>
      <div css={isPracOnboardingEnabled && styles.headingWrapper}>
        {renderHeader()}
        <Typography>{renderSubtitle()}</Typography>
        <Spacer height="one" />
      </div>

      <SSOErrorBox ssoErrors={ssoErrors} />

      <FormWrapper style={isPracOnboardingEnabled ? { maxWidth: "100%" } : null}>
        <form onSubmit={onSubmit} css={styles.form} noValidate>
          {renderFirstLastNameFields()}
          <div css={styles.row}>
            <DispensaryNameInput
              value={formData.dispensaryName}
              onChange={handleChange}
              isDirty={fieldValidationState.isDispensaryNameInvalid}
              placeholder={!isEmerson ? getDefaultDispensaryName() : null}
              required={!isEmerson}
            />
            {renderDispensaryNameTooltip()}
          </div>
          <div css={styles.row}>
            <div css={styles.designationSearchWrapper}>
              <DesignationSearch
                value={formData.practitionerType}
                setValue={handleSetDesignationValue}
                required
                data-e2e="practitioner-type-trigger"
                onBlur={() => onBlurPractitionerType()}
                hasErrors={fieldValidationState.isPractitionerTypeInvalid}
              />
              {renderPractitionerTypeError()}
            </div>
            {renderDesignationSearchTooltip()}
          </div>
          <Spacer height="half" />
          <TermsOfServiceCheckbox
            updateTimestamp={updateTimestamp}
            isChecked={!!formData.tosTimestamp}
          />
          {renderTosError()}
          <Spacer height="oneHalf" />
          {renderNextButton()}
        </form>
      </FormWrapper>
    </div>
  );
};

export { CreateAccountPageOne };
