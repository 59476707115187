import type { ReactNode } from "react";
import { useEffect } from "react";

import { AnalyticsEvent } from "@shared/hooks/useAnalyticsLogger/AnalyticsEvent";
import type {
  AnalyticsPayload,
  AnalyticsFilterPayload,
  AnalyticsOrderPayload,
  AnalyticsSearchPayload,
} from "@shared/hooks/useAnalyticsLogger/useAnalyticsLogger";
import { useAnalyticsLogger } from "@shared/hooks/useAnalyticsLogger/useAnalyticsLogger";
import { useFlippers } from "@shared/hooks/useFlippers/useFlippers";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { useUnauthUrlFields } from "@shared/hooks/useUnauthUrlFields/useUnauthUrlFields";
import { useLocation } from "@shared/react-router-dom";

interface Props {
  children: ReactNode;
  isUnauth?: boolean;
  isFsJs?: boolean;
}

const PageViewLogger = ({ children, isUnauth, isFsJs }: Props) => {
  const [isEverestMixpanelEnabled] = useFlippers("everest_mixpanel");
  const { pathname } = useLocation();
  const logAnalyticsEvent = useAnalyticsLogger();
  const unauthUrlFields = useUnauthUrlFields();
  const { isEmerson } = useSharedGlobalConfig();

  const getEventName = () => {
    if (isEmerson && isUnauth) {
      return AnalyticsEvent.EMERSON_UNAUTH_PAGE_VIEW;
    } else if (isUnauth) {
      return AnalyticsEvent.UNAUTH_PAGE_VIEW;
    } else if (isEmerson) {
      return AnalyticsEvent.EMERSON_PAGE_VIEW;
    }

    return AnalyticsEvent.STORE_PAGE_VIEW;
  };

  useEffect(() => {
    if (!isEverestMixpanelEnabled && isFsJs) return;
    const payload:
      | AnalyticsPayload
      | AnalyticsSearchPayload
      | AnalyticsFilterPayload
      | AnalyticsOrderPayload = {
      ...unauthUrlFields,
      url: pathname,
      full_url: window.location.href,
      referrer: document.referrer,
    };

    payload.rendered_width_px = Math.round(window?.innerWidth ?? 0);
    payload.rendered_height_px = Math.round(window?.innerHeight ?? 0);

    logAnalyticsEvent({
      eventName: getEventName(),
      payload,
    });
  }, [pathname]);

  return <>{children}</>;
};

export { PageViewLogger };
