import type { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Link, Separator, Spacer, Typography } from "@aviary";
import { getPhoneWithoutCountryCode } from "@aviary/components/PhoneInput";
import { l } from "@shared/locales/i18n";

import { ResendOtp } from "./ResendOtp/ResendOtp";

import * as styles from "./EnableWithSMS.styles";

interface Props {
  phoneNumber: string;
  children: ReactNode;
}

const EnableWithSMS = ({ phoneNumber, children }: Props) => {
  const { t } = useTranslation();

  const getPhoneLast4 = () => {
    const foneNumber = getPhoneWithoutCountryCode(phoneNumber);
    if (!foneNumber || foneNumber.length < 5) {
      return foneNumber;
    }

    return foneNumber.slice(-4);
  };

  const phoneLast4 = getPhoneLast4();

  return (
    <>
      <div css={styles.header}>
        <Typography type="h1">{t(l.auth.MFA.enableWithSMS.title)}</Typography>
      </div>
      <Trans
        i18nKey={l.auth.MFA.enableWithSMS.signInWithSms}
        components={{
          1: (
            <Link href="https://support.fullscript.com/articles/securing-your-practitioner-account-with-2-step-verification/#enabling-2-step-verification-sms-option" />
          ),
        }}
      />
      <Spacer height="double" />
      <Typography isSecondaryWeight isMarginless>
        {t(l.auth.MFA.step1)}
      </Typography>
      <Typography>
        <Trans i18nKey={l.auth.MFA.enableWithSMS.codeSentToMobileNumber} values={{ phoneLast4 }} />
      </Typography>
      <ResendOtp phoneLast4={phoneLast4} />
      <Separator css={styles.separator} />
      <Typography isSecondaryWeight isMarginless>
        {t(l.auth.MFA.step2)}
      </Typography>
      <Typography>{t(l.auth.MFA.enableWithSMS.enterCodeFromSms)}</Typography>
      {children}
      <Spacer height="one" />
    </>
  );
};

export { EnableWithSMS };
