import { css } from "@emotion/react";

export const header = css`
  text-align: center;
  margin-bottom: 28px;
`;

export const separator = css`
  margin: 1.5rem 0;
`;
