import { Arrange, Spacer } from "@aviary";
import { AuthFooter } from "@shared/components/AuthFooter/AuthFooter";
import { AuthHeader } from "@shared/components/AuthHeader/AuthHeader";
import { AuthHeaderWrapper } from "@shared/components/AuthHeaderWrapper/AuthHeaderWrapper";
import type { CurrentMFAProgress } from "@shared/components/MultifactorAuthentication/MFASteps";
import { SetupMFAFlow } from "@shared/components/MultifactorAuthentication/SetupMFAFlow";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { Page } from "@unauthenticated/signupSignin/components/Page/Page";
import { PageContent } from "@unauthenticated/signupSignin/components/PageContent/PageContent";

import { NavigationButtons } from "./NavigationButtons/NavigationButtons";

import * as styles from "./SetupMFAPage.styles";

interface Props {
  mfaToken?: string;
}

const SetupMFAPage = ({ mfaToken }: Props) => {
  const navigation = (currentProgress: CurrentMFAProgress) => (
    <Arrange justify="center" alignment="center">
      <Spacer height="oneHalf" />
      <NavigationButtons currentProgress={currentProgress} />
    </Arrange>
  );

  return (
    <Page>
      <PageContent>
        <AuthHeaderWrapper>
          <AuthHeader route={authRoutes.login} />
        </AuthHeaderWrapper>
        <div css={styles.contentLayout}>
          <div css={styles.stepLayout}>
            <SetupMFAFlow renderNavigation={navigation} mfaToken={mfaToken} />
          </div>
        </div>
      </PageContent>
      <AuthFooter />
    </Page>
  );
};

export { SetupMFAPage };
