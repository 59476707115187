import { useExperimentVar } from "@shared/context/experiment/experiment.store";
import { useHasMultipleStorePlatformsQuery } from "@shared/data/queries/HasMultipleStorePlatformsQuery";

import { useExperiment } from "../useExperiment/useExperiment";
import { useFlippers } from "../useFlippers/useFlippers";
import { useSharedGlobalConfig } from "../useSharedGlobalConfig/useSharedGlobalConfig";

const useSonicSegmentProvidersExperiment = () => {
  const [isSonicSegmentProvidersEnabled] = useFlippers("sonic_segment_providers");
  const { analyticsAnonymousId } = useExperimentVar();
  const { isEmerson, isUnauthenticated } = useSharedGlobalConfig();
  const { data: officeData } = useHasMultipleStorePlatformsQuery({ skip: isUnauthenticated });
  const { hasMultipleStorePlatforms } = officeData?.currentOffice?.user ?? {};

  const isExperimentEnabled =
    isSonicSegmentProvidersEnabled && !isEmerson && !hasMultipleStorePlatforms;
  const group = useExperiment({
    experiment: "sonic_segment_provider_experiment_2024",
    groups: ["CONTROL", "EXPERIMENT"],
    shouldLog: isExperimentEnabled,
    authedExperiment: false,
    unauthedExperiment: true,
    subjectId: analyticsAnonymousId,
    e2eGroup: "CONTROL",
  });

  return isExperimentEnabled && group === "EXPERIMENT";
};

export { useSonicSegmentProvidersExperiment };
