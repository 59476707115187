import { Trans, useTranslation } from "react-i18next";

import { Link, Typography } from "@aviary";
import { BackupCode } from "@shared/components/BackupCode/BackupCode";
import { l } from "@shared/locales/i18n";

import * as styles from "./DisplayBackupCode.styles";

interface Props {
  backupCode: string;
}

const DisplayBackupCode = ({ backupCode }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div css={styles.header}>
        <Typography type="h1">{t(l.auth.MFA.displayBackupCode.title)}</Typography>
      </div>
      <Trans
        i18nKey={l.auth.MFA.displayBackupCode.enableComplete}
        components={{
          1: (
            <Link href="https://support.fullscript.com/articles/securing-your-practitioner-account-with-2-step-verification/#logging-in-with-a-one-time-code" />
          ),
        }}
      />
      <BackupCode
        backupCode={backupCode}
        copyButtonTextOverride={t(l.auth.MFA.displayBackupCode.copyBackupCode)}
      />
      <Typography isSecondaryWeight isMarginless>
        {t(l.auth.MFA.displayBackupCode.important)}
      </Typography>
      <Typography>{t(l.auth.MFA.displayBackupCode.backupCodeDescription)}</Typography>
    </>
  );
};

export { DisplayBackupCode };
