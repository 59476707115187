import type * as Types from '@shared/types/graphqlGenerated.ts';

import * as Operations from './SetupMFA.mutation';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetupMfaSharedMutationVariables = Types.Exact<{
  input: Types.AuthUserTwoFactorAuthenticationSetupInput;
}>;


export type SetupMfaSharedMutation = { auth?: Types.Maybe<{ userSetupTwoFactorAuthentication?: Types.Maybe<(
      Pick<Types.AuthUserTwoFactorAuthenticationSetupPayload, 'clientMutationId' | 'qrCodeUrl' | 'manualSetupCode'>
      & { twoFactorAuthentication?: Types.Maybe<Pick<Types.TwoFactorAuthentication, 'id' | 'isEnabled' | 'isLocked' | 'otpMethod'>>, errors: Pick<Types.AuthUserTwoFactorAuthenticationSetupError, 'message'> }
    )> }> };


export type SetupMfaSharedMutationMutationFn = Apollo.MutationFunction<SetupMfaSharedMutation, SetupMfaSharedMutationVariables>;

/**
 * __useSetupMfaSharedMutation__
 *
 * To run a mutation, you first call `useSetupMfaSharedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupMfaSharedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupMfaSharedMutation, { data, loading, error }] = useSetupMfaSharedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetupMfaSharedMutation(baseOptions?: Apollo.MutationHookOptions<SetupMfaSharedMutation, SetupMfaSharedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetupMfaSharedMutation, SetupMfaSharedMutationVariables>(Operations.SetupMFA_Shared_Mutation, options);
      }
export type SetupMfaSharedMutationHookResult = ReturnType<typeof useSetupMfaSharedMutation>;
export type SetupMfaSharedMutationMutationOptions = Apollo.BaseMutationOptions<SetupMfaSharedMutation, SetupMfaSharedMutationVariables>;