import type { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Link, Separator, Spacer, Typography } from "@aviary";
import { l } from "@shared/locales/i18n";

import { QrCode } from "./QrCode/QrCode";

import * as styles from "./EnableWithAuthenticator.styles";

interface Props {
  qrCodeUrl: string;
  manualSetupCode: string;
  children: ReactNode;
}

const EnableWithAuthenticator = ({ qrCodeUrl, manualSetupCode, children }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div css={styles.header}>
        <Typography type="h1">{t(l.auth.MFA.enableWithAuthenticator.title)}</Typography>
      </div>
      <Trans
        i18nKey={l.auth.MFA.enableWithAuthenticator.signInWithAuthenticator}
        components={{
          1: (
            <Link href="https://support.fullscript.com/articles/securing-your-practitioner-account-with-2-step-verification/#enabling-2-step-verification-authenticator-app-option" />
          ),
        }}
      />
      <Spacer height="double" />
      <Typography isSecondaryWeight isMarginless>
        {t(l.auth.MFA.step1)}
      </Typography>
      <>
        <Typography>{t(l.auth.MFA.enableWithAuthenticator.scanTheQrCode)}</Typography>
        <Spacer height="quarter" />
        <QrCode url={qrCodeUrl} manualSetupCode={manualSetupCode} />
      </>
      <Separator css={styles.separator} />
      <Typography isSecondaryWeight isMarginless>
        {t(l.auth.MFA.step2)}
      </Typography>
      <Typography>{t(l.auth.enterCodeFromAuthenticator)}</Typography>
      {children}
      <Spacer height="one" />
    </>
  );
};

export { EnableWithAuthenticator };
