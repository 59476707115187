const SvgPhoneScreen = props => (
  <svg
    width={120}
    height={118}
    viewBox="0 0 720 718"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={358.761} cy={358.761} r={358.761} fill="#FFF7F9" />
    <mask
      id="mask0_2952_3189"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={0}
      width={718}
      height={718}
    >
      <circle cx={361.109} cy={359.238} r={358.761} fill="#E64565" />
    </mask>
    <g mask="url(#mask0_2952_3189)">
      <path
        d="M147.906 475.604C146.394 468.135 147.895 460.371 152.081 454.003C156.267 447.635 162.8 443.178 170.257 441.603L241.619 426.093C249.095 424.559 256.873 426.049 263.252 430.238C269.63 434.427 274.089 440.972 275.651 448.441V448.441C277.178 455.918 275.685 463.695 271.498 470.075C267.31 476.456 260.768 480.92 253.3 482.494L181.938 498.005"
        stroke="#36485C"
        strokeWidth={10}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <rect x={269.223} y={502.895} width={109.129} height={32.4144} rx={16.2072} fill="#AF2645" />
      <path
        d="M219.695 216.168H434.785V481.207H255.374L274.232 464.104L272.193 437.802L253.335 424.146L219.695 429.204V216.168Z"
        fill="#F0C4CD"
      />
      <path
        d="M393.149 191.684H253.644C243.473 191.684 233.716 195.716 226.514 202.898C219.311 210.08 215.251 219.824 215.223 229.994V431.762L241.511 426.042C248.991 424.494 256.781 425.979 263.165 430.173C269.55 434.366 274.007 440.924 275.556 448.403C277.105 455.883 275.619 463.671 271.425 470.055C267.231 476.439 260.672 480.895 253.192 482.444L215.223 490.691V564.199C215.251 574.351 219.297 584.079 226.476 591.258C233.655 598.436 243.385 602.482 253.538 602.51H393.043C403.196 602.482 412.925 598.436 420.105 591.258C427.284 584.079 431.33 574.351 431.358 564.199V229.994C431.33 219.86 427.299 210.148 420.143 202.973C412.986 195.797 403.284 191.74 393.149 191.684Z"
        stroke="#36485C"
        strokeWidth={10}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M364.44 503.406H282.328C273.584 503.406 266.496 510.493 266.496 519.236C266.496 527.978 273.584 535.066 282.328 535.066H364.44C373.183 535.066 380.271 527.978 380.271 519.236C380.271 510.493 373.183 503.406 364.44 503.406Z"
        stroke="#36485C"
        strokeWidth={10}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M460.916 392.809C456.112 390.24 450.575 389.385 445.22 390.386C439.865 391.388 435.012 394.185 431.461 398.316V493.719L470.654 425.053C473.605 419.479 474.238 412.967 472.414 406.929C470.591 400.892 466.459 395.818 460.916 392.809Z"
        stroke="#36485C"
        strokeWidth={10}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M460.916 291.156C456.112 288.587 450.575 287.733 445.22 288.734C439.865 289.735 435.012 292.532 431.461 296.663V392.067L470.654 323.401C473.605 317.827 474.238 311.314 472.414 305.277C470.591 299.24 466.459 294.165 460.916 291.156Z"
        stroke="#36485C"
        strokeWidth={10}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M237.027 776.923L302.785 645.37C330.499 639.848 355.349 624.658 372.896 602.51H253.64"
        stroke="#36485C"
        strokeWidth={10}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M147.905 475.605C155.675 555.871 162.327 589.526 196.411 616.316L140.695 703.527"
        stroke="#36485C"
        strokeWidth={10}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <circle
        cx={451.569}
        cy={213.198}
        r={124.956}
        fill="white"
        stroke="#36485C"
        strokeWidth={10}
      />
      <path
        d="M335.883 348.25L353.21 288.113L388.375 320.22L335.883 348.25Z"
        fill="white"
        stroke="#36485C"
        strokeWidth={10}
        strokeLinejoin="round"
      />
      <path
        d="M352.79 308.186L358.161 289.443L351 278.901L361.686 273L398 310.68L396.5 320L387.5 315L370 324.5L352.79 308.186Z"
        fill="white"
      />
      <rect
        x={439.765}
        y={125.022}
        width={30.3833}
        height={128.367}
        rx={15.1917}
        transform="rotate(-0.171913 439.765 125.022)"
        stroke="#36485C"
        strokeWidth={10}
      />
      <circle
        cx={455.135}
        cy={288.23}
        r={15.3316}
        transform="rotate(-0.171913 455.135 288.23)"
        stroke="#36485C"
        strokeWidth={10}
      />
    </g>
  </svg>
);
export default SvgPhoneScreen;
